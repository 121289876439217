// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-crea-il-tuo-look-js": () => import("./../../../src/pages/crea_il_tuo_look.js" /* webpackChunkName: "component---src-pages-crea-il-tuo-look-js" */),
  "component---src-pages-dettaglio-prodotto-js": () => import("./../../../src/pages/dettaglio_prodotto.js" /* webpackChunkName: "component---src-pages-dettaglio-prodotto-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fetched-metatags-js": () => import("./../../../src/pages/FetchedMetatags.js" /* webpackChunkName: "component---src-pages-fetched-metatags-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-prodotti-js": () => import("./../../../src/pages/prodotti.js" /* webpackChunkName: "component---src-pages-prodotti-js" */),
  "component---src-pages-trova-il-tuo-colore-js": () => import("./../../../src/pages/trova_il_tuo_colore.js" /* webpackChunkName: "component---src-pages-trova-il-tuo-colore-js" */)
}

